import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import LandingPageLayout from '../layouts/LandingPageLayout.vue'

// import RouteViewComponent from '../layouts/RouterBypass.vue'
import { getStorage } from '../utils/auth'
import { USER_ROLES_TO_ROUTE } from '../data/constants'

let roleId: any
if (getStorage()) roleId = getStorage().role_id

const landingPageRoutes = [
  {
    name: 'beranda',
    path: '',
    component: () => import('../pages/landing/Index.vue'),
  },
  {
    name: 'daftar-pengumuman',
    path: 'daftar-pengumuman',
    component: () => import('../pages/landing/DaftarPengumumanPage.vue'),
  },
  {
    name: 'daftar-pelatihan',
    path: 'daftar-pelatihan',
    component: () => import('../pages/landing/DaftarPelatihanPage.vue'),
  },
  {
    name: 'detail-pelatihan',
    path: 'detail-pelatihan',
    component: () => import('../pages/landing/DetailPelatihanPage.vue'),
  },
  {
    name: 'deskrip-pengumuman',
    path: 'deskrip-pengumuman',
    component: () => import('../pages/landing/PengumumanPage.vue'),
  },
  {
    name: 'daftar-galeri',
    path: 'galeri',
    component: () => import('../pages/landing/GaleriPage.vue'),
  },
  {
    name: 'pengajuan-mtu',
    path: 'pengajuan-mtu',
    component: () => import('../pages/landing/PengajuanMTUPage.vue'),
  },
]

const adminRoutes = [
  {
    name: 'profile',
    path: 'profile',
    component: () => import('../pages/profile/ProfilePage.vue'),
    meta: { access: ['3', '4', '5'] },
  },
  {
    name: 'dashboard',
    path: 'dashboard',
    component: () => import('../pages/admin/dashboard/Dashboard.vue'),
    meta: { access: ['2', '3'] },
  },

  // admin super
  {
    name: 'pengguna',
    path: 'pengguna',
    component: () => import('../pages/users/UsersPage.vue'),
    meta: { access: ['1'] },
  },

  // admin super
  {
    name: 'pengguna_role',
    path: 'pengguna_role',
    component: () => import('../pages/users-role/UsersRolePage.vue'),
    meta: { access: ['1'] },
  },

  // admin super
  {
    name: 'log_sistem',
    path: 'log_sistem',
    component: () => import('../pages/log/LogPage.vue'),
    meta: { access: ['1'] },
  },

  // admin super, admin dinas, admin ppkd
  {
    name: 'pengumuman',
    path: 'pengumuman',
    component: () => import('../pages/pengumuman-fe/PengumumanFEPage.vue'),
    meta: { access: ['1', '2', '3'] },
  },

  // admin super
  {
    name: 'galeri',
    path: 'galeri',
    component: () => import('../pages/galeri/GaleriPage.vue'),
    meta: { access: ['1'] },
  },

  // admin super
  {
    name: 'ppkd_master',
    path: 'ppkd_master',
    component: () => import('../pages/ppkd/PPKDPage.vue'),
    meta: { access: ['1'] },
  },

  // admin dinas, admin ppkd, peserta
  {
    name: 'pelatihan',
    path: 'pelatihan',
    // component: () => import(`../pages/new-pelatihan/${roleId === '4' ? 'PelatihanPage' : 'PpkdPelatihanPage'}.vue`),
    component: () => import(`../pages/new-pelatihan/PpkdPelatihanPage.vue`),
    meta: { access: ['2', '3', '4'] },
  },

  // admin super
  {
    name: 'kejuruan',
    path: 'kejuruan',
    component: () => import('../pages/kejuruan/KejuruanPage.vue'),
    meta: { access: ['1'] },
  },

  // admin dinas
  {
    name: 'program',
    path: 'program',
    component: () => import('../pages/program/ProgramPage.vue'),
    meta: { access: ['2'] },
  },

  // admin ppkd
  {
    name: 'unggah_sertifikat',
    path: 'unggah_sertifikat',
    component: () => import('../pages/new-certificate/CertificatePage.vue'),
    meta: { access: ['3', '4'] },
  },

  // admin ppkd
  {
    name: 'penyerapan',
    path: 'penyerapan',
    component: () => import('../pages/penempatan/PenempatanPage.vue'),
    meta: { access: ['3'] },
  },

  // admin ppkd
  {
    name: 'pengajar',
    path: 'pengajar',
    component: () => import('../pages/pengajar/PengajarPage.vue'),
    meta: { access: ['3'] },
  },

  // admin ppkd
  {
    name: 'peserta',
    path: 'peserta',
    component: () => import('../pages/peserta/PesertaPage.vue'),
    meta: { access: ['3'] },
  },

  // admin ppkd
  {
    name: 'kusioner',
    path: 'kusioner',
    component: () => import('../pages/kusioner/KusionerPage.vue'),
    meta: { access: ['3', '4'] },
  },

  // admin ppkd
  {
    name: 'permohonan',
    path: 'permohonan',
    component: () => import('../pages/permohonan/PermohonanPage.vue'),
    meta: { access: ['3'] },
  },

  // peserta
  {
    name: 'sertifikat',
    path: 'sertifikat',
    component: () => import('../pages/certificate/CertificateUserPage.vue'),
    meta: { access: ['4'] },
  },
  {
    name: 'materi-peserta',
    path: 'materi-peserta',
    component: () => import('../pages/peserta-materials/MateriPesertaPage.vue'),
    meta: { access: ['4'] },
  },

  // pengajar
  {
    name: 'materi',
    path: 'materi',
    component: () => import('../pages/materials/MateriPage.vue'),
    meta: { access: ['4', '5'] },
  },

  // pengajar
  {
    name: 'absensi',
    path: 'absensi',
    component: () => import('../pages/new-attendance/AbsensiPage.vue'),
    meta: { access: ['5'] },
  },

  // pengajar
  {
    name: 'lpj',
    path: 'lpj',
    component: () => import('../pages/lpjk/LPJPage.vue'),
    meta: { access: ['5'] },
  },
]

const setRoutesAdminByRole = () => {
  if (!roleId) return adminRoutes

  const routes = adminRoutes.filter(
    (val: any) => val.meta.access.includes(String(roleId)) || val.meta.access.includes('all'),
  )

  return routes
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'landing',
    component: LandingPageLayout,
    children: landingPageRoutes,
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    redirect: { name: roleId ? USER_ROLES_TO_ROUTE[roleId] : 'dashboard' },
    children: setRoutesAdminByRole(),
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'masuk',
        path: 'masuk',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'daftar',
        path: 'daftar',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'otp',
        path: 'otp',
        component: () => import('../pages/auth/Otp.vue'),
      },
      {
        name: 'reset-password',
        path: 'reset-password',
        component: () => import('../pages/auth/ResetPassword.vue'),
      },
      {
        name: 'rubah-password',
        path: 'rubah-password/:id',
        component: () => import('../pages/auth/ChangePassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      // {
      //   path: '',
      //   redirect: { name: 'login' },
      // },
      {
        name: 'update-profile',
        path: 'update-profile',
        component: () => import('../pages/auth/UpdateProfile.vue'),
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: roleId ? USER_ROLES_TO_ROUTE[roleId] : 'dashboard' },
  },
]

export const getPaths = (type: string) => {
  const routesByType: any = {
    landing_page: { parentPath: '/', routes: landingPageRoutes },
    admin: { parentPath: '/admin/', routes: adminRoutes },
  }
  let paths = []
  paths = routesByType[type].routes.map((val: any) => {
    return `${routesByType[type].parentPath}${val.path}`
  })
  return paths
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
